import styled from 'styled-components';

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent || 'space-between'};
    position: absolute;
    background-color: white;
    z-index: 2;

    bottom: -1.5rem;
    padding: 1rem;
    left: -1.5rem;

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 75vw;
        bottom: -10vw;
        right: 0;
        left: auto;
        border: 1px solid black;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        bottom: 0;
        width: 50vw;
    }
`;

export const BoldLink = styled.div`
    font-weight: 500;
    text-transform: uppercase;
    color: var(--trust);

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`

export const Container = styled.div`
    width: 100%;
    // display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Introduction = styled.div`
    display: flex;
    width: 100%;
    padding: 5vw 0vw;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    background-color: #f7f3ec;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        flex-wrap: wrap;
        flex-direction: column-reverse; 
        align-items: center;
    }
`;

export const IntroductionText = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 100%;
        gap: 5vw;
        margin-bottom: 10vw;
    }
`;

export const Zwanger = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    overflowX: hidden;
    padding: 5vw 0vw;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 1.5vw;
    justify-content: center;
    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
        gap: 10vw 0;
    }
`;

export const HulpVoeden = styled.div`
    background-color: #F6EFE7;
    width: 100%;
    position: relative;
    
    overflowX: hidden;
    padding: 5vw 0vw;
    justify-content: center;
    
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    
    text-align: left;
    gap: 10vw;
    
    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10vw 0;
    }
`;

export const Ontdek = styled.div`
    width: 100%;
    position: relative;
    padding: 5vw 0 5vw 12vw;
    
    text-align: left;
    background-color: #FDFCFA;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 10vw;
    }
`;

export const Planning = styled.div`
    width: 100%;
    background-color: #F1E3D4;
    padding: 5vw 0;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 0;
    }
`;

export const Workshops = styled.div`
    padding: 5vw 0 5vw 12.5vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    position: relative;

    background-color: #FDFCFA;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px) {
        padding: 10vw 5vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px) {
        padding: 5vw 7vw 5vw 7vw;
    }
`

export const Kosten = styled.div`
    padding: 5vw 0 5vw 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1vw;
    position: relative;

    background-color: #F6F3EC;

    @media screen and (min-width: 1025px){
        padding: 5vw 0 5vw 20vw;
    }

    @media screen and (max-width: 766px) {
        padding: 4vw;
    }
`;

export const AanmeldenContainerText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        max-width: 45vw;
        gap: 2vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        gap: 1vw;
    }
`;

export const MapContainer = styled.div`
    border: 1vw solid ${props => props.borderColor};
    position: relative;
    width: 95vw;
    height: 95vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 40vw;
        height: 40vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        width: 30vw;
        height: 30vw;
    }
`;

export const AanmeldenContainer = styled.div`
    padding: 3rem 2rem;
    flex-wrap: wrap;
    position: relative;
    z-index: 6;
    display: flex;
    gap: 10vw;
    justify-content: space-between;
    background-color: ${props => props.category === 'ouders' ? '#f1e3d4' : '#f6efe7'};
    scroll-margin-top: -5em;

    &:focus {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }

    @media (min-width: 641px)  {
        gap: 1vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        padding: 10rem;
    }
`

export const Ervaringen = styled.div`
    width: 100%;
    background-color: white;

    padding: 5vw 0vw;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
    text-align: center;
`

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};
`;

export const Benieuwd = styled.div`
    background-color: white;
    width: 100%;
    padding: 5vw 0vw;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
`;

export const CircleClick = styled.div`
    position: absolute;
    z-index: 5;
    left: -7vw;
    bottom: 0.5vw;
    
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 1vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 25vw;
        height: 25vw;
        bottom: -10vw;
        padding: 4vw;
    }
`;

export const CircleInfo = styled.div`
    position: absolute;
    z-index: 5;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100%;
    height: 100%;

    /* Desktop: min-height */
    @media (min-width: 1024px) {
       border-radius: 50%;
        opacity: 0.9;
        right: -20%;
        bottom: 15%;
        width: 20rem;
        height: 20rem;  
    }

`;

export const LinkClick = styled.div`
    margin: 5px 0;
    cursor: pointer;
    word-break: break-all;

    &:hover {
        font-weight: 600;
    }
`;

export const Goededoel = styled.div`
    width: 100%;

    background-color: white;
    position: relative;

    display: flex;
    align-items: flex-end;
    gap: 5vw;

    padding: 0 5vw;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (min-width: 1025px){
        padding: 0 10vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 1025px){
        padding: 5vw 10vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 770px){
        padding: 5vw;
    }

    
`;

export const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: #FDFCFA;
    width: 100px;
    height: 100px;
`;