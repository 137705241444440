import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Papa from 'papaparse';

import { Typography, Button, TextField, Checkbox } from '../../components';
import { AanmeldenContainer, CircleClick, CircleInfo, AanmeldenContainerText, BoldLink, ContactForm, MapContainer } from './styled';

import Map, { NavigationControl, AttributionControl, Marker } from 'react-map-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

import { HiMapPin } from "react-icons/hi2";
import { GeneralContext } from '../../App';
import { scrollIntoView } from '../../helpers/ScrollToTop';
import { AiOutlineClose } from 'react-icons/ai';
import axiosInstance from '../../actions/axios';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const initialViewState = {
    longitude: 4.9,
    latitude: 52.1,
    zoom: 6,
    bearing: 0,
    pitch: 0,
    width: 200,
}

export default function Aanmelden(props) {
    const { category } = props;

    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const mapRef = useRef();
    const divMapRef = useRef();
    const infoCircleRef = useRef();

    const [ error, setError ] = useState('');
    const [ consultantList, setConsultantList ]  = useState([]);
    const [ onHover, setOnHover ] = useState(false);
    const [ popupInfo, setPopupInfo ] = useState({});
    const [ postCode, setPostCode ] = useState('');
    const [ consultant, setConsultant ] = useState({});

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ andersWeten, setAndersWeten ] = useState('');
    const [ practice, setPractice ] = useState('');

    const [ directAanmelden, setDirectAanmelden ] = useState(false);
    const [ benieuwdDatums, setBenieuwdDatums ] = useState(false);
    const [ wilAndersWeten, setWilAndersWeten ] = useState(false);
    const [ pakket1, setPakket1 ] = useState(false);
    const [ pakket2, setPakket2 ] = useState(false);
    const [ pakket3, setPakket3 ] = useState(false);
    const [ workshopError, setWorkshopError ] = useState('');

    const [ emailMessage, setEmailMessage ] = useState('');

    const workshopList = [
        { name: 'Solo pakket (zwangerschap)', value: pakket1, setValue: setPakket1 },
        { name: 'Solo pakket (kraamperiode)', value: pakket2, setValue: setPakket2 },
        { name: 'Compleet pakket (zwangerschap én kraamperiode)', value: pakket3, setValue: setPakket3 },
    ]

    useEffect(() => {
        if (consultantList.length === 0 && error.length === 0) {
            fetch( './lactatiekundigen.csv' )
                .then( response => response.text() )
                .then( responseText => {
                    
                    const data = Papa.parse(responseText);
                    
                    const columns = data['data'][0];
                    parseCsv(data['data'], columns);
                })
            .catch(error => {
                console.log(error);
                setError(error);
            })
        }
    })

    async function parseCsv(data, columns) {
        let newConsultantList = [];
        for (let i=1; i<data.length; i++) {
            let consultantObj = {}
            let item = data[i];

            for (let j=0; j<columns.length; j++) {
                let columnName = columns[j];

                if (item[j] !== undefined) {
                    consultantObj[columnName] = item[j];
                } else {
                    consultantObj[columnName] = '';
                }
            }

            // fetch the longitude and latitude and save it back into the csv
            if ((consultantObj['lengtegraad'].length === 0 || consultantObj['breedtegraad'].length === 0) && consultantObj['postcode'].length > 0) {
                consultantObj = await fetchLocation(consultantObj).then(result => result);
            }

            if (consultantObj['bedrijf'] !== '') {
                newConsultantList.push(consultantObj);
            }
            
        }

        setConsultantList( newConsultantList );
        // return newConsultantList;
    }

    async function fetchLocation(consultantObj) {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${consultantObj['postcode']}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&country=NL,BE&language=nl`;
                // let response = await fetch(url);
                
        await fetch(url)
            .then(response => response.json())
            .then(data => {
                let feature = data.features[0];
                let center = feature?.center;
                consultantObj['lengtegraad'] = center[0];
                consultantObj['breedtegraad'] = center[1];
            })
            .catch(error => {
                console.log(error);
                setConsultantList( [] );
                setError(error);
            })

        return consultantObj;
    }

    // sets the language of the map
    const mapRefCallback = useCallback((ref) => {
        if (ref !== null) {
            //Set the actual ref we use elsewhere
            mapRef.current = ref;
            const map = ref;
    
            //Add language control that updates map text i18n based on browser preferences
            const language = new MapboxLanguage({ defaultLanguage: 'mul'});
            map.addControl(language);
        }
    }, []);

    const findArea = () => {
        setPopupInfo({});
        // scrollIntoView(divMapRef)

        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${postCode}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&country=NL,BE&language=nl`;
        postCode && fetch(url)
            .then(response => response.json())
            .then(data => {
                const feature = data.features[0];
                const center = feature?.center;
                center.length === 2 && mapRef.current?.flyTo({center: [center[0], center[1]], zoom: 8, duration: 3000}) 
            })
        .catch((error) => {
            setConsultantList( [] );
            setError(error);
        });
    };

    const unsetAanmelden = () => {
        setDirectAanmelden(false);
        setWorkshopError('');
        workshopList.map(workshop => workshop.setValue(false));
    }

    const setWorkshopValue = (workshop) => {
        // if currently true, set all to false
        if (workshop.value) {
            workshopList.map(workshop => workshop.setValue(false));
        } else {
            // set all to false except current workshop;
            workshopList.map(workshop => workshop.setValue(false));
            workshop.setValue(true);
            setWorkshopError('');
        }
    }

    async function onSubmit(event) {
        event.preventDefault();

        // error handling for aanmelden
        if (directAanmelden) {
            // at least one workshop should be selected
            if (!workshopList.map(workshop => workshop.value).includes(true)) {
                setWorkshopError('Als je je wilt aanmelden, moet je eerst een workshop kiezen.');
                return;
            }
        }

        // error handling for phone number
        let newPhoneNumber = phoneNumber;
        if (!newPhoneNumber.includes('+') && newPhoneNumber[0] === '0') {
            newPhoneNumber = '+31' + newPhoneNumber.substring(1)
        }

        const formValues = {
            'sender_category': category,
            'sender_first_name': firstName,
            'sender_last_name': lastName,
            'sender_phone_number': newPhoneNumber,
            'sender_email': email,
            'sender_practice': practice,
            'consultant_name': consultant.lactatiekundige,
            'consultant_company_name': consultant.bedrijf,
            'consultant_email': consultant.emailadres,
            'wants_register': directAanmelden,
            'wants_workshop_name': '',
            'wants_dates': benieuwdDatums,
            'wants_info': wilAndersWeten,
            'info_wanted': '',
        }

        if (directAanmelden) {
            const selectedWorkshop = workshopList.filter(workshop => workshop.value === true)[0];
            formValues['wants_workshop_name'] = selectedWorkshop.name
        }

        if ((wilAndersWeten) || (category === 'verloskundige')) {
            formValues['info_wanted'] = andersWeten
        }

        const res = await axiosInstance.post('/register-email/', { ...formValues });
        
        if (res.status === 200) {
            let thanksMessage = `Goed bezig! Op naar een fijne en moeiteloze ervaring bij het voeden van jullie kindje! Lactatiekundige ${consultant.lactatiekundige} zal contact met je opnemen.`
            if (category === 'verloskundige') {
                thanksMessage = `Dankjewel voor je bericht! Lactatiekundige ${consultant.lactatiekundige} zal contact met je opnemen.`
            }

            setEmailMessage(thanksMessage);
            setPopupInfo({});
            setConsultant({});
            mapRef.current?.flyTo(initialViewState);
        } else {
            setWorkshopError(`Er is iets fout gegaan, probeer het opnieuw of stuur direct een mail naar de lactatiekundige via ${consultant.emailadres}.`)
        }
    }

    return (
        <AanmeldenContainer ref={props.parentRef} category={category} id='aanmelden' tabIndex="-1" bottomPadding={directAanmelden ? '400px' : '12vw'}>
            <AanmeldenContainerText>
                <Typography type='h2' style={{ fontSize: hasPhone ? '2em' : hasTablet ? '2em' : '2.5em', color: '#032d34' }}>{props.title}</Typography>
                {props.subtitle && <Typography type='caps' style={{ fontSize: '1.2em', color: 'var(--trust)'}}>{props.subtitle}</Typography>}
                {category === 'ouders' ? 
                    <Typography style={{ width: hasPhone ? '80vw' : '28vw'}}>Vind hier contact met een aangesloten borstvoedingsexpert bij jullie in de buurt. Je kunt bij haar terecht voor de aanmelding, beschikbare data van de workshops en andere vragen.</Typography>
                    : <Typography style={{ width: hasPhone || hasTablet ? '100%' : '25vw'}}>Benieuwd of er lactatiekundigen in jouw werkgebied zijn aangesloten bij Goed Aangelegd?</Typography>
                }
                <div style={{ display: 'flex', alignItems: 'center', gap: hasPhone ? '3vw' : '1vw', flexWrap: 'wrap', margin: '2vw 0 0 0'}}>
                    <TextField
                        id="Postcode"
                        value={postCode}
                        placeholder={!postCode ? "Postcode" : ""}
                        style={{ margin: '0.2vw 0 0 0', width: hasPhone ? '50vw' : '80px'}}
                        customStyle='default'
                        onChange={(event) => setPostCode(event.target.value)}
                        maxLength={4}
                    />
                    <BoldLink
                        onClick={findArea}
                    >
                        Vind een lactatiekundige →
                    </BoldLink>
                </div>
                {category === 'verloskundige' &&
                    <div style={{ marginTop: hasPhone ? '5vw' : '1vw'}}>
                        <Typography style={{ width: hasPhone || hasTablet ? '100%' : '25vw' }}>Is er geen lactatiekundige dicht in de buurt en/of ken je een lactatiekundige die open staat voor innovatieve borstzorg? Spread the word over Goed Aangelegd! 
                        {/* Je neemt eenvoudig contact met haar op <LineClick onClick={props.downloadMailSjabloon}>met behulp van deze e-mail</LineClick> die wij alvast voor je hebben opgesteld.*/}</Typography> 
                        {/* <Button 
                            buttonstyle='fourthly'
                            text='Download de mail'
                            style={{ margin: hasPhone ? '5vw 0' : '1vw 0'}}
                            onClick={props.downloadMailSjabloon}
                        /> */}
                    </div>
                }
            </AanmeldenContainerText>
            <MapContainer 
                ref={divMapRef}
                borderColor={Object.keys(popupInfo).length > 0 ? '#dbb28c' : 'white'}
                onMouseOver={() => setOnHover(true)}
                onMouseOut={() => setOnHover(false)}
            >
                {onHover && !emailMessage && Object.keys(popupInfo).length === 0 && Object.keys(consultant).length === 0 &&
                    <CircleClick>
                        <Typography type='caps' style={{ fontSize: hasPhone ? '0.5em' : '1em', fontWeight: '600', color: '#dbb28c'}}>Klik op een van de lactatie{hasPhone && '-'}kundigen</Typography>
                    </CircleClick>
                }
                {Object.keys(popupInfo).length > 0 && Object.keys(consultant).length === 0 &&
                    <CircleInfo ref={infoCircleRef}>
                        <Typography type='h2' style={{ margin: '0 0 0.5vw 0', padding: '0 1vw', fontSize: '1.5em' }}>{popupInfo.bedrijf}</Typography>
                        <Typography style={{ margin: '0 0 1vw 0'}}>{popupInfo.lactatiekundige}</Typography>
                        <Typography>{popupInfo.straat}</Typography>
                        <Typography>{popupInfo.postcode} {popupInfo.plaats}</Typography>
                        <Button 
                            text='Neem contact op'
                            buttonstyle='secondary'
                            style={{ margin: hasPhone ? '5vw 0 0 0' : '1vw 0 0 0', opacity: 1}}
                            onClick={() => { setConsultant(popupInfo) }}
                        />
                        {(hasPhone || hasTablet) && 
                            <div style={{ position: 'absolute', top: '2vw', right: '2vw' }}>
                                <AiOutlineClose size={hasPhone ? '5vw' : '3vw'} onClick={() => setPopupInfo({})} />
                            </div>
                        }
                    </CircleInfo>
                }
                <Map
                    initialViewState={initialViewState}
                    reuseMaps
                    mapStyle="mapbox://styles/kirstenmaas/cljinuplb003a01qy4qmjf9ds"
                    style={{ opacity: 0.8 }}
                    attributionControl={false}
                    // width={200}
                    ref={mapRefCallback}
                >
                    <NavigationControl position="top-left" />
                    <AttributionControl />
                    {consultantList.length > 0 && consultantList.map((city, i) => {
                        if (city.lengtegraad && city.breedtegraad) {
                                return <Marker 
                                key={`marker-${i}`}
                                longitude={Number(city.lengtegraad)} 
                                latitude={Number(city.breedtegraad)} 
                                anchor="bottom"
                                style={{ cursor: 'pointer'}}
                                onClick={e => {
                                // If we let the click event propagates to the map, it will immediately close the popup
                                // with `closeOnClick: true`
                                    e.originalEvent.stopPropagation();
                                    setPopupInfo(city);
                                    hasPhone && scrollIntoView(infoCircleRef);
                                    mapRef.current?.flyTo({center: [Number(city.lengtegraad)+0.02, Number(city.breedtegraad)-0.02], zoom: 11, duration: 3000})
                                }}
                            >
                                <HiMapPin size={hasPhone ? '7vw' : 'clamp(30px, 1.5vw, 35px)'} style={{ fill: popupInfo?.lengtegraad === city.lengtegraad ? '#145f68' : '#92330f', stroke: 'white', strokeWidth: '0.5px'}} />   
                            </Marker>
                        }
                        
                        return null;
                    })}
                </Map>
                {Object.keys(consultant).length > 0 && !emailMessage && <ContactForm onSubmit={onSubmit}>
                        <Typography style={{ margin: '0 0 1vw 0'}}>Geweldig! Je hebt een borstvoedingsexpert gekozen. Laat ons hieronder weten waarom je contact wilt opnemen. De betreffende borstvoedingsexpert zal zo snel mogelijk reageren op je verzoek of vraag.</Typography>
                        <TextField 
                            value={`${consultant.bedrijf} - ${consultant.lactatiekundige}`}
                            disabled
                            customStyle='primary'
                        />
                        <TextField 
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            placeholder='Voornaam'
                            required={true}
                            customStyle='primary'
                        />
                        <TextField 
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            placeholder='Achternaam'
                            required={true}
                            customStyle='primary'
                        />
                        <TextField 
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder='E-mailadres'
                            email
                            required={true}
                            customStyle='primary'
                        />
                        <TextField 
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            placeholder='Telefoonnummer'
                            tel
                            required={true}
                            customStyle='primary'
                        />
                        {category === 'verloskundige' && <TextField 
                            value={practice}
                            onChange={(event) => setPractice(event.target.value)}
                            placeholder='Praktijk'
                            required={true}
                            customStyle='primary'
                        />}
                        {category === 'verloskundige' && <TextField 
                                value={andersWeten}
                                onChange={(event) => setAndersWeten(event.target.value)}
                                placeholder='Typ hier je bericht'
                                customStyle='primary'
                        />}
                        {category === 'ouders' && <Checkbox 
                            isChecked={directAanmelden}
                            text='Ik wil me direct aanmelden'
                            onClick={() => directAanmelden ? unsetAanmelden() : setDirectAanmelden(true)}
                            style={{ marginTop: '2vw' }}
                        />}
                        {directAanmelden &&
                            <div style={{ fontSize: '14px', margin: '1vw 0 2vw 2.5vw'}}>
                                Leuk dat je je wilt aanmelden! In welk pakket heb je interesse?
                                {workshopList.map((workshop, i) => (
                                    // this is a radio button that looks like a checkbox
                                    <Checkbox 
                                        key={i}
                                        circleWidth='20px'
                                        textSize='14px'
                                        boxLeft='5px'
                                        boxWidth='20px'
                                        boxBottom='-1vw'
                                        isChecked={workshop.value}
                                        text={workshop.name}
                                        onClick={() => setWorkshopValue(workshop)}
                                        style={{ margin: '1vw 0 1vw 0' }}
                                    />
                                ))}
                            </div>
                        }
                        {category === 'ouders' && <Checkbox 
                            isChecked={benieuwdDatums}
                            text='Ik ben benieuwd naar de datums'
                            onClick={() => setBenieuwdDatums(!benieuwdDatums)}
                            style={{ marginTop: hasPhone ? '3vw' : '0vw'}}
                        />}
                        {category === 'ouders' && <div style={{ display: 'flex', width: '100%', gap: hasPhone ? '3vw' : '1vw' }}>
                            <Checkbox 
                                isChecked={wilAndersWeten}
                                // text='Ik ben benieuwd naar de datums'
                                onClick={() => setWilAndersWeten(!wilAndersWeten)}
                                style={{ marginTop: hasPhone ? '3vw' : '0vw'}}
                            />
                            <TextField 
                                value={andersWeten}
                                onChange={(event) => setAndersWeten(event.target.value)}
                                placeholder='Ik wil iets anders weten, namelijk ...'
                                disabled={!wilAndersWeten}
                                customStyle='primary'
                                width={hasPhone ? '70vw' : hasTablet ? '40vw' : '27vw'}
                            />
                        </div>}
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1vw', margin: '1vw 0 0 0' }}>
                            
                            <Button 
                                onClick={() => { setConsultant({}); setPopupInfo({}); setDirectAanmelden(false); mapRef.current?.flyTo(initialViewState)}}
                                buttonstyle='sixthly'
                                text='Terug naar kaart'
                                disabled={workshopError.length > 0}
                                style={{ borderRadius: '2%', margin: hasPhone ? '3vw 0' : '1vw 0 0 0'}}
                            />
                            <Button 
                                type='submit'
                                buttonstyle='primary'
                                text='Versturen'
                                disabled={workshopError.length > 0}
                                style={{ borderRadius: '2%', margin: hasPhone ? '3vw 0' : '1vw 0 0 0'}}
                            />
                        </div>
                        
                        {workshopError && <Typography style={{ color: 'red', margin: '8px 0 0 0' }}>{workshopError}</Typography>}
                </ContactForm>}
                {emailMessage.length > 0 && 
                    <ContactForm>
                        <div>
                            <Typography type='h2'>Je bericht is verstuurd!</Typography>
                            <Typography style={{ margin: '1vw 0 1vw 0'}}>{emailMessage}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1vw', margin: '1vw 0 0 0' }}>
                            <Button 
                                onClick={() => { setConsultant({}); setPopupInfo({}); setDirectAanmelden(false); setEmailMessage(''); mapRef.current?.flyTo(initialViewState)}}
                                buttonstyle='sixthly'
                                text='Terug naar kaart'
                                disabled={workshopError.length > 0}
                                style={{ borderRadius: '2%', margin: hasPhone ? '3vw 0' : '1vw 0 0 0'}}
                            />
                        </div>
                    </ContactForm>
                }
            </MapContainer>
            
        </AanmeldenContainer>
    )
}
